@use '@vtx-styles/settings/colors.scss';
@use '~normalize.css/normalize';
@use '@styles/fonts/index.scss' as vcr-fonts;
@use '@vtx-styles/reset.scss';
@use '@vtx-styles/components/index.scss' as vtx-components;

@use '~@styles/utility/index.scss';

body {
  background-color: colors.$vtx-colour-4;
}
