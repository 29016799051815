@use '@vtx-styles/settings/colors.scss';

$colors: (
  'colour-error': colors.$vtx-colour-error,
  'colour-1': colors.$vtx-colour-1,
  'colour-2': colors.$vtx-colour-2,
  'colour-3': colors.$vtx-colour-3,
  'colour-4': colors.$vtx-colour-4,
  'colour-5': colors.$vtx-colour-5,
  'colour-6': colors.$vtx-colour-6,
);

$gradients: (
  'gradient-1': colors.$vtx-gradient-1,
  'gradient-2':
    'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 51.59%, rgba(0, 0, 0, 0.81) 100%);'
);

@each $key, $value in $colors {
  .vcr-text-#{$key} {
    color: $value;
  }
}

@each $key, $value in $colors {
  .vcr-icon-#{$key} {
    fill: $value;
  }
}

@each $key, $value in $colors {
  .vcr-bg-#{$key} {
    background-color: $value;
  }
}

@each $key, $value in $gradients {
  .vcr-bg-#{$key} {
    background: $value;
  }
}

@each $key, $value in $colors {
  .vcr-border-#{$key} {
    border-color: $value;
  }
}
