@use '@styles/settings/size.scss';
@use '@vtx-styles/settings/spacers.scss';


.vcr-logo {
  height: size.$vcr-top-bar-content-height;
  display: flex;
  align-items: center;
  margin-top: spacers.$vtx-spacer-4;
  margin-left: spacers.$vtx-spacer-4;
  &__img {
    width: 87px;
    height: 40px;
    border: none;
    object-fit: contain;
  }
}
