@use '~@vtx-styles/settings/spacers.scss';
@use '~@styles/settings/size.scss';

.vcr-action-bar-cta {
  height: size.$vcr-filter-bar-height;
  margin-top: spacers.$vtx-spacer-5;
  margin-bottom: spacers.$vtx-spacer-4;
  display: flex;
  align-items: center;
}
