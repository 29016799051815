@use "sass:math";

$cols: (
  "cols-1": repeat(1, minmax(0, 1fr)),
  "cols-2": repeat(2, minmax(0, 1fr)),
  "cols-3": repeat(3, minmax(0, 1fr)),
  "cols-4": repeat(4, minmax(0, 1fr)),
  "cols-5": repeat(5, minmax(0, 1fr)),
  "cols-6": repeat(6, minmax(0, 1fr)),
  "cols-7": repeat(7, minmax(0, 1fr)),
  "cols-8": repeat(8, minmax(0, 1fr)),
  "cols-9": repeat(9, minmax(0, 1fr)),
  "cols-10": repeat(10, minmax(0, 1fr)),
  "cols-11": repeat(11, minmax(0, 1fr)),
  "cols-12": repeat(12, minmax(0, 1fr)),
  "cols-none": none
);

$span: (
  "auto":	auto,
  "span-1":	span 1 / span 1,
  "span-2":	span 2 / span 2,
  "span-3":	span 3 / span 3,
  "span-4":	span 4 / span 4,
  "span-5":	span 5 / span 5,
  "span-6":	span 6 / span 6,
  "span-7":	span 7 / span 7,
  "span-8":	span 8 / span 8,
  "span-9":	span 9 / span 9,
  "span-10":	span 10 / span 10,
  "span-11":	span 11 / span 11,
  "span-12":	span 12 / span 12,
  "span-full":	math.div(1, -1),
);

$flow: (
  "row":	row,
  "col":	column,
  "row-dense":	row dense,
  "col-dense":	column dense,
);


@each $key, $value in $cols {
  .vcr-grid-#{$key} {
    grid-template-columns: $value;
  }
}

@each $key, $value in $span {
  .vcr-col-#{$key} {
    grid-column: $value;
  }
}

@each $key, $value in $span {
  .vcr-row-#{$key} {
    grid-row: $value;
  }
}

@each $key, $value in $flow {
  .vcr-grid-flow-#{$key} {
    grid-auto-flow: $value;
  }
}
