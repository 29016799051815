$values: (
  '1': 1,
  '2': 2,
  '3': 3
);

@each $key, $value in $values {
  .vcr-line-clamp-#{$key} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $value;
    overflow: hidden;
  }
}

@mixin line-clamp($value) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $value;
  overflow: hidden;
}
