@use '@vtx-styles/settings/border.scss';
@use '@vtx-styles/settings/colors.scss';

$px: 1px;

$sizes: (
  '1': $px,
  '2': 2px,
  '3': 3px,
  '4': 4px
);

$directions: (
  't': top,
  'l': left,
  'b': bottom,
  'r': right
);

$radiuses: (
  '1': border.$vtx-border-radius-1,
  '2': border.$vtx-border-radius-2,
);

.vcr-b-none {
  border: none;
}

@each $spacing, $value in $sizes {
  .vcr-b-#{$spacing} {
    border: #{$value} solid;
  }
}

@each $spacing, $value in $radiuses {
  .vcr-b-rad-#{$spacing} {
    border-radius: #{$value};
  }
}

@each $key, $dir in $directions {
  .vcr-b#{$key} {
    border-#{$dir}: $px solid;
  }

  @each $spacing, $value in $sizes {
    .vcr-b#{$key}-#{$spacing} {
      border-#{$dir}: #{$value} solid;
    }
  }
}

.vcr-border-none {
  border: none;
}

.vcr-border-primary {
  border: border.$vtx-border-1 solid colors.$vtx-colour-5;
}

.vcr-border-radius-1 {
  border-radius: border.$vtx-border-radius-1;
}

.vcr-border-radius-2 {
  border-radius: border.$vtx-border-radius-2;
}

.vcr-border-radius-bottom-1 {
  border-bottom-left-radius: border.$vtx-border-radius-1;
  border-bottom-right-radius: border.$vtx-border-radius-1;
}

.vcr-border-radius-top-1 {
  border-top-left-radius: border.$vtx-border-radius-1;
  border-top-right-radius: border.$vtx-border-radius-1;
}

.vcr-border-collapse_vertical {

  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  & + & {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:not(&:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
