$vcr-nav-bar-width: 256px;
$vcr-action-bar-width: 316px;
$vcr-top-bar-content-height: 48px;
$vcr-filter-bar-height: 48px;
$vcr-nav-item-height: 48px;

$vcr-brand-header-width: 372px;

$vcr-brand-card-width: 186px;

$vcr-brand-card-figure-height: 40px;

$vcr-auth-content-width: 768px;

$vcr-selectable-table-cell-height: 48px;

$vcr-card-placeholder-size: 180px;
