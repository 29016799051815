




























































@use '@vtx-styles/settings/colors.scss';
@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/shadow.scss';
@use '@vtx-styles/settings/media-query.scss';
@use '~@styles/components/counter-badge.scss';
@use '~@styles/settings/z-index.scss';


$contact-requests-toggler-size: 64px;
$contact-requests-toggler-icon-size: 36px;
$contact-requests-toggler-counter-position: -3px;

.vcr-contact-popup-toggler {
  position: fixed;
  z-index: z-index.$z-index-10000;
  right: spacers.$vtx-spacer-3;
  bottom: spacers.$vtx-spacer-3;
  width: $contact-requests-toggler-size;
  height: $contact-requests-toggler-size;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: colors.$vtx-gradient-1;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    box-shadow: shadow.$vtx-shadow-card;
  }

  &_pulse {
    animation: pulse 2s infinite;
  }

  &_anonymous {
    background: colors.$vtx-colour-2;
  }

  .vtx-icon {
    fill: colors.$vtx-colour-4;
    width: $contact-requests-toggler-icon-size;
    height: $contact-requests-toggler-icon-size;
  }

  @include media-query.vtx-mobile-max {
    right: spacers.$vtx-spacer-2;
    bottom: spacers.$vtx-spacer-2;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(colors.$vtx-colour-1, 0.8);
    }

    70% {
      box-shadow: 0 0 0 16px rgba(colors.$vtx-colour-1, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(colors.$vtx-colour-1, 0);
    }
  }

  &__counter {
    position: absolute;
    top: $contact-requests-toggler-counter-position;
    right: $contact-requests-toggler-counter-position;
  }
}
