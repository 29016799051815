$modes: (
  'block': block,
  'inline-block': inline-block,
  'inline': inline,
  'inline-flex': inline-flex,
  'hidden': none,
  'flex': flex,
  'grid': grid
);

@each $key, $value in $modes {
  .vcr-#{$key} {
    display: $value;
  }
}

.vcr-display-flex {
  display: flex;
}

.vcr-align-items-center {
  align-items: center;
}

.vcr-justify-content-center {
  justify-content: center;
}

.vcr-flex-1 {
  flex: 1;
}

.vcr-flex-0 {
  flex: 0 0 auto;
}

.vcr-flex-wrap {
  flex-wrap: wrap;
}
