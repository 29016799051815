



















@use '~@vtx-styles/settings/spacers.scss';
.vcr-filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__filters {
    display: flex;
    align-items: center;

    * + * {
      margin-left: spacers.$vtx-spacer-3;
    }
  }
}
