@use '@styles/settings/z-index.scss';

.vcr-manage-layout {
  position: relative;
  $imageOpacity: 0.1;

  &__background-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: z-index.$vcr-background-images;
    pointer-events: none;
  }


  &__background-image {
    position: absolute;
    background-repeat: no-repeat;
  }

  &__background-image_1 {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-image: url('@img/abstract-1.svg');
    background-position: bottom left;
  }

  &__background-image_2 {
    width: 50%;
    height: 100%;
    top: -5%;
    right: 0;
    background-image: url('@img/pattern_gradient.svg');
    background-position: 100% 75%;
    background-size: cover;
    opacity: $imageOpacity;
  }
}
