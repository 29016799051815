@use '@vtx-styles/settings/colors.scss';

.vcr-text-colour-1 {
  color: colors.$vtx-colour-1;
}

.vcr-text-colour-2 {
  color: colors.$vtx-colour-2;
}

.vcr-text-colour-3 {
  color: colors.$vtx-colour-3;
}

.vcr-text-colour-4 {
  color: colors.$vtx-colour-4;
}

.vcr-text-colour-6 {
  color: colors.$vtx-colour-6;
}

.vcr-text-colour-error {
  color: colors.$vtx-colour-error;
}

.vcr-text-center {
  text-align: center;
}

.vcr-text-left {
  text-align: left;
}

.vcr-text-right {
  text-align: right;
}

.vcr-text-transform-uppercase {
  text-transform: uppercase;
}

.vcr-text-transform-capitalize {
  text-transform: capitalize;
}

.vcr-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
