@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/border.scss';
@use '@vtx-styles/settings/colors.scss';
@use '@vtx-styles/settings/opacity.scss';
@use '@vtx-styles/settings/shadow.scss';
@use '@styles/settings/size.scss';
@use '@styles/settings/nav-menu.scss';

.vcr-nav-list {
  &__item {
    display: flex;
    flex-direction: column;

    &_active {
      .vcr-nav-list__item-action {
        @include shadow.vtx-gradient-1-shadow;
        color: colors.$vtx-colour-4;
        background: colors.$vtx-gradient-1;
      }

      .vtx-icon {
        fill: colors.$vtx-colour-4;
      }
    }

    & + & {
      margin-top: spacers.$vtx-spacer-0;
    }
  }

  &__item-action {
    border-top-right-radius: border.$vtx-border-radius-1;
    border-bottom-right-radius: border.$vtx-border-radius-1;
    display: flex;
    align-items: center;
    min-height: size.$vcr-nav-item-height;
    width: 100%;
    padding: 0 spacers.$vtx-spacer-1 0 nav-menu.$spacer-left;

    &_collapsable {
      padding-right: 0;
    }

    > .vtx-icon {
      &:first-child {
        margin-right: nav-menu.$icon-margin;
      }
    }
  }

  &__toggle-icon {
    display: inline-flex;
    height: size.$vcr-nav-item-height;
    padding: 0 spacers.$vtx-spacer-1;
    align-items: center;
  }

  &__item-text {
    flex-grow: 1;
  }
}
