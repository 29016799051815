@use '@vtx-styles/settings/shadow.scss';
@use '@vtx-styles/settings/colors.scss';
@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/border.scss';
@use '@vtx-styles/settings/media-query.scss';
@use '~@styles/settings/z-index.scss';

.vcr-contact-popup-view {
  position: fixed;
  z-index: z-index.$z-index-10000 + 1;
  right: spacers.$vtx-spacer-3;
  bottom: 122px;
  height: 68%;
  max-height: 620px;
  width: 360px;
  background: colors.$vtx-colour-4;
  box-shadow: shadow.$vtx-shadow-card;
  border-radius: border.$vtx-border-radius-1;

  @include media-query.vtx-mobile-max {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &__button-close {
    position: absolute;
    right: 0;
    bottom: calc(100% + #{spacers.$vtx-spacer-1});
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: colors.$vtx-colour-2;
    color: colors.$vtx-colour-4;

    @include media-query.vtx-mobile-max {
      background-color: transparent;
      width: 48px;
      height: 48px;
      bottom: auto;
      top: spacers.$vtx-spacer-3;
      right: spacers.$vtx-spacer-0;
    }
  }

  ::v-deep &__header {
    padding: spacers.$vtx-spacer-3;
    border-top-left-radius: border.$vtx-border-radius-1;
    border-top-right-radius: border.$vtx-border-radius-1;
    background: colors.$vtx-gradient-1;
    color: colors.$vtx-colour-4;

    @include media-query.vtx-mobile-max {
      border-radius: 0;
    }

    &_big {
      $overflow-size: 50px;
      margin-bottom: - ($overflow-size + spacers.$vtx-spacer-2);
      padding: spacers.$vtx-spacer-2 spacers.$vtx-spacer-2 $overflow-size + spacers.$vtx-spacer-2;
    }

    &_anonymous {
      background: colors.$vtx-colour-2;
    }
  }

  ::v-deep &__body {
    flex-grow: 1;
    border-bottom-left-radius: border.$vtx-border-radius-1;
    border-bottom-right-radius: border.$vtx-border-radius-1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @include media-query.vtx-mobile-max {
      border-radius: 0;
    }
  }

  ::v-deep &__content {
    background-color: colors.$vtx-colour-4;
    margin-bottom: spacers.$vtx-spacer-2;
  }

  ::v-deep .vtx-icon {
    fill: currentColor;
  }
}
