@use '@vtx-styles/settings/spacers.scss';
@use '@styles/settings/size.scss';

.vcr-user-menu {
  height: size.$vcr-top-bar-content-height;
  margin-top: spacers.$vtx-spacer-4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
