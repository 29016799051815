// TODO [refactoring]: move to design system?
@use '@vtx-styles/settings/spacers.scss';

$spacers: (
  '0': spacers.$vtx-spacer-0,
  '1': spacers.$vtx-spacer-1,
  '2': spacers.$vtx-spacer-2,
  '3': spacers.$vtx-spacer-3,
  '4': spacers.$vtx-spacer-4,
  '5': spacers.$vtx-spacer-5,
  '6': spacers.$vtx-spacer-6,
  '7': spacers.$vtx-spacer-7,
  'auto': auto,
);

@each $key, $value in $spacers {
  .vcr-margin {
    &-#{$key} {
      margin: $value;
    }
    &-bottom-#{$key} {
      margin-bottom: $value;
    }
    &-top-#{$key} {
      margin-top: $value;
    }
    &-left-#{$key} {
      margin-left: $value;
    }
    &-right-#{$key} {
      margin-right: $value;
    }
    &-y-#{$key} {
      margin-top: $value;
      margin-bottom: $value;
    }
    &-x-#{$key} {
      margin-left: $value;
      margin-right: $value;
    }
  }

  .vcr-padding {
    &-#{$key} {
      padding: $value;
    }
    &-bottom-#{$key} {
      padding-bottom: $value;
    }
    &-top-#{$key} {
      padding-top: $value;
    }
    &-left-#{$key} {
      padding-left: $value;
    }
    &-right-#{$key} {
      padding-right: $value;
    }
    &-y-#{$key} {
      padding-top: $value;
      padding-bottom: $value;
    }
    &-x-#{$key} {
      padding-left: $value;
      padding-right: $value;
    }
  }

  .vcr-gap-#{$key} {
    gap: $value;
  }

  .vcr-column-gap-#{$key} {
    column-gap: $value;
  }

  .vcr-row-gap-#{$key} {
    row-gap: $value;
  }
}
