@use '@vtx-styles/settings/opacity.scss';
@use '@vtx-styles/settings/border.scss';
@use '@vtx-styles/settings/shadow.scss';
@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/icon.scss';
@use '@vtx-styles/settings/colors.scss';
@use '@styles/settings/nav-menu.scss';

.vcr-sub-nav-list {
  $dot-size: 4px;

  position: relative;

  &__item {
    position: relative;

    &:before {
      position: absolute;
      display: block;
      content: '';
      left: #{nav-menu.$spacer-left + (icon.$vtx-icon-size * 0.5) - ($dot-size * 0.5)};
      top: 50%;
      transform: translateY(-50%);
      width: $dot-size;
      height: $dot-size;
      border-radius: 50%;
      background-color: rgba(colors.$vtx-colour-3, opacity.$vtx-opacity-2);
      z-index: 1;
    }

    &:first-child .vcr-sub-nav-list__item-divider:first-child {
      display: none;
    }

    &:last-child .vcr-sub-nav-list__item-divider:last-child {
      display: none;
    }

    &_active {
      .vcr-sub-nav-list__item-action {
        @include shadow.vtx-gradient-1-shadow;
        color: colors.$vtx-colour-4;
        background: colors.$vtx-gradient-1;
        opacity: 1;
      }

      &:before {
        background-color: colors.$vtx-colour-4;
      }

      .vcr-sub-nav-list__item-divider {
        display: none;
      }

      & + .vcr-sub-nav-list__item .vcr-sub-nav-list__item-divider:first-child {
        display: none;
      }
    }

    &_previous .vcr-sub-nav-list__item-divider:last-child {
      display: none;
    }
  }

  &__item-action {
    display: flex;
    align-items: center;
    opacity: opacity.$vtx-opacity-5;
    padding-top: spacers.$vtx-spacer-1;
    padding-bottom: spacers.$vtx-spacer-1;
    padding-left: nav-menu.$spacer-left + icon.$vtx-icon-size + nav-menu.$icon-margin;
    padding-right: spacers.$vtx-spacer-1;
    border-top-right-radius: border.$vtx-border-radius-1;
    border-bottom-right-radius: border.$vtx-border-radius-1;
    width: 100%;
  }

  &__item-divider {
    $width: 1px;

    position: absolute;
    display: block;
    content: '';
    left: #{nav-menu.$spacer-left + (icon.$vtx-icon-size * 0.5) - ($width * 0.5)};
    width: $width;
    height: calc(50% - #{($dot-size * 2) + ($width * 2)});
    background-color: rgba(colors.$vtx-colour-3, opacity.$vtx-opacity-2);

    &:first-child {
      top: 0;
    }

    &:last-child {
      bottom: 0;
    }
  }
}
