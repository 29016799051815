@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/colors.scss';
@use '@vtx-styles/settings/opacity.scss';
@use '@styles/settings/size.scss';
@use '@styles/components/manage-layot.scss';

.vcr-app {
  display: flex;

  &__nav-bar-wrapper,
  &__content-wrapper,
  &__action-bar-wrapper {
    overflow-y: auto;
    height: 100vh;
  }

  &__nav-bar-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: size.$vcr-nav-bar-width;
    padding-right: spacers.$vtx-spacer-3;
  }

  &__content-wrapper {
    flex-grow: 1;
    padding: 0 spacers.$vtx-spacer-3;
    padding-bottom: spacers.$vtx-spacer-5;
    position: relative;
  }

  &__action-bar-wrapper {
    flex-shrink: 0;
    width: size.$vcr-action-bar-width;
    margin-left: 52px;
    padding: 0 spacers.$vtx-spacer-4;

    &_wide {
      margin: 0 0 0 spacers.$vtx-spacer-3;
      padding: 0 spacers.$vtx-spacer-4 0 0;
    }
  }

  &__auth-manage {
    display: flex;
    justify-content: flex-end;
    padding-top: spacers.$vtx-spacer-4;
  }

  &_manage-layout {
    position: relative;

    .vcr-app__content-wrapper_auth {
      display: flex;
      align-items: center;
      margin-right: size.$vcr-action-bar-width;
    }

    .vcr-legal {
      background: transparent;

      padding: spacers.$vtx-spacer-4 spacers.$vtx-spacer-1 spacers.$vtx-spacer-4 spacers.$vtx-spacer-4;
      color: colors.$vtx-colour-4;
    }
  }
}
