$directions: (
  'row': row,
  'row-reverse': row-reverse,
  'col': column,
  'col-reverse': column-reverse
);

$wrap: (
  'wrap': wrap,
  'wrap-reverse': wrap-reverse,
  'no-wrap': nowrap
);

@each $key, $value in $directions {
  .vcr-flex-#{$key} {
    flex-direction: $value;
  }
}

@each $key, $value in $wrap {
  .vcr-flex-#{$key} {
    flex-wrap: $value;
  }
}

.vcr-justify-start {
  justify-content: flex-start;
}

.vcr-justify-end {
  justify-content: flex-end;
}

.vcr-justify-center {
  justify-content: center;
}

.vcr-self-justify-center {
  justify-self: center;
}

.vcr-justify-between {
  justify-content: space-between;
}

.vcr-justify-around {
  justify-content: space-around;
}

.vcr-justify-evenly {
  justify-content: space-evenly;
}

.vcr-self-align-center {
  align-self: center;
}

.vcr-flex-shrink-0 {
  flex-shrink: 0;
}

.vcr-flex-shrink-1 {
  flex-shrink: 1;
}

.vcr-flex-grow-0 {
  flex-grow: 0;
}

.vcr-flex-grow-1 {
  flex-grow: 1;
}

.vcr-items-start {
  align-items: flex-start;
}

.vcr-items-end {
  align-items: flex-end;
}

.vcr-items-center {
  align-items: center;
}

.vcr-items-baseline {
  align-items: baseline;
}

.vcr-items-stretch {
  align-items: stretch;
}

.vcr-justify-self-start {
  justify-self: start;
}

.vcr-justify-self-end {
  justify-self: end;
}
