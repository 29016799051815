@use '@vtx-styles/settings/spacers.scss';
@use '@vtx-styles/settings/opacity.scss';
@use '@styles/settings/size.scss';

.vcr-page-topbar {
  margin-top: spacers.$vtx-spacer-4;
  margin-bottom: spacers.$vtx-spacer-4;
  height: size.$vcr-top-bar-content-height;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: spacers.$vtx-spacer-3;

  &__title-greeting {
    opacity: opacity.$vtx-opacity-5;
  }
}
