@use '@vtx-styles/settings/spacers.scss';

$sizes: (
  'auto': auto,
  'px': 1px,
  '1\\/2': 50%,
  '1\\/3': 33.333333%,
  '2\\/3': 66.666667%,
  '1\\/4': 25%,
  '2\\/4': 50%,
  '3\\/4': 75%,
  '1\\/5': 20%,
  '2\\/5': 40%,
  '3\\/5': 60%,
  '4\\/5': 80%,
  'full': 100%,
  'screen': 100vw,
  'screen\\/2': 50vw,
  'touch': spacers.$vtx-spacer-5,
);

@each $key, $value in $sizes {
  .vcr-w-#{$key} {
    width: $value;
  }
  .vcr-min-w-#{$key} {
    min-width: $value;
  }
  .vcr-max-w-#{$key} {
    max-width: $value;
  }
}

.vcr-width-full {
  width: 100%;
}
