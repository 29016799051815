@use './spacers.scss';

$directions: (top, right, bottom, left);

$positions: (
  'static': static,
  'fixed': fixed,
  'absolute': absolute,
  'relative': relative,
  'sticky': sticky
);

@each $key, $value in $positions {
  .vcr-#{$key} {
    position: $value;
  }
}

@each $key in $directions {
  .vcr-#{$key} {
    #{$key}: 0;
  }

  @each $spacing, $value in spacers.$spacers {
    .vcr-#{$key}-#{$spacing} {
      #{$key}: $value;
    }
  }
}
