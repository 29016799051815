













































































@use '~@styles/app.scss';
@use '~@components/nav-bar/nav-bar.scss'; // we need nav bar styles to properly align all elements in navbar
@use '~@styles/components/action-bar-cta.scss';

.vcr-app__action-bar-wrapper {
  margin-left: 0;
}

.vcr-action-bar-cta {
  justify-content: flex-end;
}
