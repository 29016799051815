@use '~@vtx-styles/settings/spacers.scss';
@use '~@vtx-styles/settings/colors.scss';
@use '~@vtx-styles/settings/opacity.scss';

.vcr-legal {
    // border radius and gradient are specific only for this element
    border-top-right-radius: 25px;
    background: radial-gradient(100% 221.17% at 100% 0%, #f1f1f1 0%, rgba(255, 255, 255, 0) 100%);

    padding: spacers.$vtx-spacer-4;
    color: rgba(colors.$vtx-colour-3, opacity.$vtx-opacity-3);

    &__header {
      margin-bottom: spacers.$vtx-spacer-0;
    }

    &__link {
      text-decoration: underline;
    }
}
