@use '~@vtx-styles/settings/spacers.scss';
@use '~@styles/settings/size.scss';

.vcr-nav-menu {
  // Compensate the difference in height between `nav-menu` and `page-filter-bar` to align them
  // verticaly

  $height-diff: (size.$vcr-nav-item-height - size.$vcr-filter-bar-height) * 0.5;
  margin-top: spacers.$vtx-spacer-5 - $height-diff;
  display: flex;
  flex-direction: column;
}
