@use '@vtx-styles/settings/colors.scss';
@use '@vtx-styles/settings/typography.scss';

.vcr-counter-badge {
  $badge-size: 22px;
  $badge-size-small: 16px;

  @include typography.vtx-typo-inputfield-label;

  border-radius: 50%;
  width: $badge-size;
  height: $badge-size;
  background: colors.$vtx-colour-error;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: colors.$vtx-colour-4;
  flex-shrink: 0;
  flex-grow: 0;

  &_small {
    width: $badge-size-small;
    height: $badge-size-small;
    font-size: 10px; // it's from design
  }
}
