@use '@vtx-styles/settings/spacers.scss';

$content-width: 614px;

.vcr-page-error {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vcr-page-error__content {
  width: 100%;
  max-width: $content-width;
  background: no-repeat center center;
  background-size: contain;  
  padding: spacers.$vtx-spacer-5 spacers.$vtx-spacer-1;
}

.vcr-page-error__content_404 {
  background-image: url(@img/error-404.svg);
}

.vcr-page-error__content_500 {
  background-image: url(@img/error-500.svg);
}

.vcr-page-error__title {
  text-align: center;
}

.vcr-page-error__description {
  text-align: center;
  margin-top: spacers.$vtx-spacer-3;
}

.vcr-page-error__cta {
  text-align: center;
  margin-top: spacers.$vtx-spacer-5;
}
