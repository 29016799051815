











































.vcr-datatable__sort-icon {
  vertical-align: top;
}
